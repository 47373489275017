<template>
  <div class="container" data-v-35cf7caa="">
        <div class="row" data-v-35cf7caa="">
            <div class="col-12" data-v-35cf7caa="">
                <div class="sign__content" style="min-height: 91vh;">
                    <form class="sign__form sign__trailer card">
                        <a class="sign__logo" style="margin-bottom: 10px;">
                            <img src="../../assets/zain_gray.png" style="margin-top: 15px;margin-bottom: 20px;">
                        </a>
                        <p style="color: #fff">اشترك الان وتمتع بافضل تجربة كوميدية مع نادي الكوميديا</p>
                        <center data-v-35cf7caa="">
                            <div class="sign__group" style="justify-content: center;" data-v-35cf7caa="">
                            <a @click="DSPLinkSub()" class="sign__btn button pointer header-btn" style="width: 100%;">اشتراك</a>
                            </div>
                        </center>
                        <p style="color: #fff">او سجل دخولك اذا كنت مشترك مسبقا</p>
                        <center data-v-35cf7caa="">
                            <div class="sign__group" style="justify-content: center;" data-v-35cf7caa="">
                            <router-link to="/Login" class="sign__btn button pointer header-btn" style="width: 100%;">تسجيل دخول</router-link>
                            </div>
                        </center>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
   setup() {
       const DSPLinkSub = () => {
           window.open(`https://dsplp.sd.zain.com/?p=9619984619`, "_blank");
       }
       return { DSPLinkSub };
   }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>